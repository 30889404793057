import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { Link, navigate } from "gatsby";
import * as Yup from "yup";
import swal from "sweetalert";

import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import BlankLayout from "../../components/layouts/BlankLayout";
import { supabase } from "../../helpers/supabase";
import { AuthContext } from "../../contexts/AuthContext";
import SEO from "../../components/layouts/SEO";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(8).label("Password"),
});

const LoginPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { getUser } = useContext(AuthContext);
  const { errors, handleChange, handleBlur, handleSubmit, values } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      setLoading(true);
      const { error } = await supabase.auth.signIn({ email, password });

      if (error) {
        swal(error.message, "", "error");
      } else {
        await getUser();
        navigate("/");
      }
      setLoading(false);
    },
  });

  return (
    <BlankLayout>
      <SEO
        title="Login | Ensemble"
        url="https://ensembleai.io/auth/login"
        desc="we help enterprise organisations build and run advanced data, analytics and AI capabilities based on modern cloud-native technology."
        ogImage="https://ensembleai.io/assets/img/home.png"
      />
      <div className="w-full max-w-none md:max-w-[480px] md:bg-white md:shadow-4xl p-6 md:p-10 rounded-2xl flex flex-col gap-6">
        <h1 className="text-[26px] font-semibold text-black-light text-center">
          Sign In
        </h1>
        <p className="text-sm text-black-light text-center">
          Don't have an account?{" "}
          <Link to="/auth/register" className="gradient-red-text">
            See A Demo
          </Link>
        </p>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col items-center space-y-6">
            <Input
              inputProps={{ name: "email" }}
              type="text"
              placeholder="Email"
              label="Email"
              className="!w-full"
              onHardChange={handleChange}
              onBlur={handleBlur}
              value={values.email ?? ""}
              error={errors.email ?? ""}
              isRequired
            />
            <Input
              inputProps={{ name: "password" }}
              type="password"
              placeholder="Password"
              label="Password"
              className="!w-full"
              onHardChange={handleChange}
              value={values.password ?? ""}
              error={errors.password ?? ""}
              isRequired
            />
            <Button
              label="Login"
              actionType="submit"
              loading={loading}
              className="!w-full md:!w-[172px]"
            />
          </div>
        </form>
        {/* <div className="flex justify-center">
          <Link to='/auth/forgot-password' className="text-sm text-grey-lite">
            Forgot Password?
          </Link>
        </div> */}
      </div>
    </BlankLayout>
  );
};

export default LoginPage;
